import React from "react";
import styles from "./Button.module.scss";

interface BtnProps {
    children: React.ReactNode;
    href?: string;
    onClick?: () => void;
}
 
const Btn: React.FunctionComponent<BtnProps> = props => {
    const {children, onClick, href} = props;
    if(href) return ( <a href={href} target="_blank" className={styles.btn}>{children}</a> );
    return ( <button className={styles.btn} onClick={onClick}>{children}</button> );
}
 
export default Btn;