import React from "react";
import styles from "./WorkingWith.module.scss";
interface WorkingWithProps {}
 
const WorkingWith: React.FunctionComponent<WorkingWithProps> = () => {
    const logos:Array<string> = [
        "aws-logo.png", "chatgpt.png", "found-hub.png", "github-logo.png", "vowix-logo.png"
    ]
    return ( <div className={styles.workingWith}>
        <div className={`${styles.container} container`}>
            <h2 className={styles.title}>We care elderly pets. <br/>We can do our best with their help.</h2>
            <p>Caring for elderly pets involves working with companies like AWS, GitHub, and Microsoft to provide innovative solutions. Utilizing their technologies, we can ensure proper nutrition, a comfortable environment, and regular health monitoring. These partnerships enable us to offer advanced care, adapting to the pets' changing needs with the help of cutting-edge tools and services.</p>
            <div className={styles['logo-lists']}>
                {logos && logos.map((logo, i) => {
                    return (<img src={`/images/working/${logo}`} key={`logo-key-${i}`}/>)
                })}
            </div>
        </div>
    </div> );
}
 
export default WorkingWith;