import React from "react";
import styles from "./Promotion.module.scss";
interface PromotionProps {
    href: string;
} 
interface PromotionState {} 
class Promotion extends React.Component<PromotionProps, PromotionState> {
    render() { 
        const { href } = this.props;
        return ( <a href={href} className={styles.promotion}>
            <span className={styles.innerwrapper}>
                <span className={styles.txt}>
                    Our protection for <br />your family.
                    <span className={styles.btn}>
                        Know more
                    </span>
                </span>
            </span>
        </a> );
    }
}
 
export default Promotion;