import React from "react";
import styles from "./NearbyVet.module.scss";
import { Link } from "react-router-dom";

interface NearbyVetProps {}
interface NearbyVetState {}

class NearbyVet extends React.Component<NearbyVetProps, NearbyVetState> {
    render() { 
        return ( <div className={styles.nearbyVet}>
            <h2 className={styles.title}> Nearby Vet </h2>
            <div className={styles.nearbyVet__wrapper}>
                <Link to={'#'}>
                    <div className={styles.card}>
                        <div className={styles.card__img}>
                            <img src="/images/vet/vet-pic.png" />
                        </div>
                        <div className={styles.card__content}>
                            <div className={styles.card__content__name}>
                                Vets for Pets - Altrincham
                            </div>
                            <div className={styles.card__content__phone}>
                                01619279318
                            </div>
                            
                        </div>
                    </div>
                </Link>
                <Link to={'#'}>
                    <div className={styles.card}>
                        <div className={styles.card__img}>
                            <img src="/images/vet/vet2.png" />
                        </div>
                        <div className={styles.card__content}>
                            <div className={styles.card__content__name}>
                                Vets4Pets - Trafford Park
                            </div>
                            <div className={styles.card__content__phone}>
                                01619279318
                            </div>
                            
                        </div>
                    </div>
                </Link>
            </div>
        </div> );
    }
}
 
export default NearbyVet;