const header = {
    nav: [
        // {
        //     id:"details",
        //     txt:"Details"
        // },
        // {
        //     id:"ai",
        //     txt:"AI"
        // },
        // {
        //     id:"social",
        //     txt:"Social"
        // },
        // // {
        //     id:"price",
        //     txt:"Price"
        // }
    ],
    contact: {
        txt: "Contact us"
    }
}

export default header;