import React from "react";
import styles from "./ShortCuts.module.scss";
import { Link } from "react-router-dom";

interface ShortCutsProps {
    data: any;    
}
 
const ShortCuts: React.FunctionComponent<ShortCutsProps> = props => {
    const {data} = props
    return ( <div className={styles.shortCuts}>
        <div className={styles.shortCuts__top}>
          <div>Short cut</div>
          <div>
            <a href="#">+ Add</a>
          </div>
        </div>
        <div className={styles.shortCuts__items}>
          {data && data.map((item:any, i:number) => {
            const {ico, txt, link} = item;
            return (
              <Link to={link} className={styles.shortCuts__item} key={`shortCut${i}`}>
                <div className={styles.shortCuts__item__ico}>
                  <img src={ico} alt="short cut ico" />
                </div>
                <div className={styles.shortCuts__item__txt}>{txt}</div>
              </Link>
            );
          })}
        </div>
        
      </div> );
}
 
export default ShortCuts;