const details = {
    title: "More details",
    txt: "Bevetu is designed with the specific needs of elder pets in mind. Our app features:",
    nav: [
        {
            src: "/images/details_ico/monitor.svg",
            title: "Health Monitoring",
            txt: "Track medications, vet visits, and daily routines."
        },
        {
            src: "/images/details_ico/alerts.svg",
            title: "Personalized Alerts",
            txt: "Never miss a dose or appointment with timely reminders."
        },
        {
            src: "/images/details_ico/Expert.svg",
            title: "Expert Advice",
            txt: "Access information from veterinarians and pet care experts."
        },
        {
            src: "/images/details_ico/Diet.svg",
            title: "Diet & Nutrition",
            txt: "Get tailored diet plans for your senior pet’s needs."
        },
        {
            src: "/images/details_ico/activity.svg",
            title: "Activity Tracking",
            txt: "Monitor and manage your pet's physical activity levels."
        },
        {
            src: "/images/details_ico/Phone_light.svg",
            title: "Community Support",
            txt: "Connect with other pet owners and share experiences."
        }
    ]
}

export default details;
