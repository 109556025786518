import React from "react";
import styles from "./Spending.module.scss";
import Btn from "../../components/Btn/Btn";
import CommonHead from "../../components/CommonHead/CommonHead";

interface SpendingProps {
    
}
 
const Spending: React.FunctionComponent<SpendingProps> = () => {
    return ( <div className={styles.spending}>
        <CommonHead noPic={true} />
        <img src="/images/spending/spending1.png" alt="top" />
        <div className={styles.btnwrapper}>
            <Btn>Scan recipt</Btn>
        </div>            
        <img src="/images/spending/spending2.png" alt="top" />
    </div>  );
}
 
export default Spending;