import React from "react";
import styles from "./Body.module.scss";

interface BodyProps {
    children?: React.ReactNode
}
interface BodyState {}

class Body extends React.Component<BodyProps, BodyState> {
    render() { 
        const { children } = this.props
        return ( <div className={styles.body}>
            {children ? <>
                {children}
            </>:
            <>
                <h3>Chief Complaint:</h3>
                <p>Coco presented with vomiting and diarrhea for the past two days.</p>
                <img src="/images/records/Chart.js.png" alt="chart one" />
                <h3>History:</h3>
                <p>Coco is a three-year-old Labrador Retriever who lives indoors with her owner, Ms. Johnson, and another dog. She is up to date on vaccinations and heartworm prevention. Coco is known to have a sensitive stomach and has had occasional episodes of gastrointestinal upset in the past, usually associated with dietary indiscretion. She has been eating and drinking less than usual over the past two days.</p>
                <h3>Physical Examination:</h3>
                <img src="/images/records/Chartist.png" alt="chart one" />
                <p>On examination, Coco appeared alert but mildly depressed. Her vital signs were within normal limits. She was mildly dehydrated, with tacky mucous membranes. Abdominal palpation revealed mild discomfort. Auscultation of the heart and lungs revealed no abnormalities. Examination of the oral cavity showed no abnormalities.</p>
                <h3>Diagnostic Tests:</h3>
                <img src="/images/records/Chartist2.png" alt="chart one" />
                <p>Complete Blood Count (CBC): Within normal limits</p>
                <p>Serum Biochemistry Profile: Mild elevation in kidney parameters suggestive of dehydration</p>
                <p>Fecal Examination: Positive for presence of Giardia cysts</p>
                <h3>Assessment:</h3>
                <p>Based on the history, physical examination, and diagnostic test results, Coco is diagnosed with gastroenteritis secondary to Giardia infection. Giardiasis is a common cause of gastrointestinal upset in dogs, characterized by vomiting, diarrhea, and abdominal discomfort.</p>
                <h3>Treatment Plan:</h3>
                <img src="/images/records/Chartist2.png" alt="chart one" />
                <p>Administration of anti-parasitic medication (e.g., metronidazole) to treat the Giardia infection.</p>
                <p>Fluid therapy to correct dehydration.</p>
                <p>Prescription of a bland diet to ease gastrointestinal symptoms.</p>
                <p>Environmental cleaning and disinfection to prevent reinfection.</p>
                <p>Monitoring of clinical signs and response to treatment.</p>
                <h3>Prognosis:</h3>
                <p>The prognosis for Coco's condition is good with appropriate treatment. Giardiasis is typically responsive to medication, and Coco is expected to recover fully with supportive care.</p>
                <h3>Follow-Up:</h3>
                <p>Coco will need to complete the full course of medication as prescribed. Ms. Johnson will be advised to monitor Coco closely for any recurrence of clinical signs and to follow proper hygiene measures to prevent reinfection.</p>
            </>}
        </div> );
    }
}
 
export default Body;