import React from "react";
import RegistrationForm from "./RegistrationForm";

interface InfoInputProps {}
 
const InfoInput: React.FunctionComponent<InfoInputProps> = () => {
    return ( <>
        <RegistrationForm />
    </> );
}
 
export default InfoInput;