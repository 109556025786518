const details = {
    title: "For you who give all the best to your pet.",
    txt: "With Flockr Premium you are even more in control of your pet's life.",
    nav: [
        {
            src: "https://flockr.social/icons/calendar.svg",
            title: "Pet's routine",
            txt: "Configure your pet's routine, feeding time, outings, etc., and receive reminders."
        },
        {
            src: "https://flockr.social/icons/notification.svg",
            title: "Recurring notifications",
            txt: "Register once and never forget the date of your pet's vaccines, deworming, anti-flea and other important events."
        },
        {
            src: "https://flockr.social/icons/spending.svg",
            title: "Spending",
            txt: "Add and organize expenses with your pet, find out how much he has spent in his entire life and how much he is spending per month."
        },
        {
            src: "https://flockr.social/icons/health-records.svg",
            title: "Health Records",
            txt: "Have your pet's medical history in the palm of your hand whenever you need it. Will your pet stay with a Pet Sitter or will you have an appointment at a new veterinarian? Share the complete medical record in PDF."
        },
        {
            src: "https://flockr.social/icons/files.svg",
            title: "Files",
            txt: "Upload PDF files to make your records more complete."
        },
        {
            src: "https://flockr.social/icons/appointments.svg",
            title: "Ask to Veterinarians",
            txt: "As a Premium user you can ask questions to our vets. Your questions will be answered within our blog along with our weekly content."
        },
        {
            src: "https://flockr.social/icons/verified.svg",
            title: "Verified profile",
            txt: "Show the entire network that your pet has the best care possible with the verified badge on your profile."
        },
        {
            src: "https://flockr.social/icons/camera.svg",
            title: "Photos customizations",
            txt: "In addition to posting the best moments with your pet, you can customize your photos with our exclusive stickers and frames."
        },
    ]
}

export default details;
