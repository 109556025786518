import React from "react";
import styles from "./PageBtn.module.scss";
import Head from "../../components/Head/Head";

interface PageBtnProps {}

const PageBtn: React.FunctionComponent<PageBtnProps> = ({  }) => {

    return (
        <button className={styles.submit}>Submit</button>
    );
};

export default PageBtn;
