import React, { FunctionComponent, useEffect, useState } from "react";
import Home from "./Home";
import Details from "./Details";

interface PetDetailsProps {}
 
const PetDetails: FunctionComponent<PetDetailsProps> = () => {
    const [showDetail, setShowDetail] = useState<boolean>(false)

    useEffect(() => {
       window.scrollTo(0,0) 
    },[])

    const changePage = () => {
        setShowDetail(!showDetail)
    }
    return ( <div>
        {!showDetail && <Home onClick={changePage} />}
        {showDetail && <Details closeClick={changePage} />}
    </div> );
}
 
export default PetDetails;