import React, { useState } from "react";
import styles from "./Hero.module.scss";
import Video from "../Video/Video";
import Btn from "../Btn/Btn";

interface HeroProps {
    content:HeroContentProps;
}

interface HeroContentProps {
    subtitle: string;
    title: string;
    txt: string;
    btn: {
        txt: string;
    }
}
 
const Hero: React.FunctionComponent<HeroProps> = props => {
    const {subtitle, title, txt, btn} = props.content
    const [showVideo, setShowVideo] = useState<boolean>(false)
    const setVideoTrigger = () => setShowVideo(!showVideo)
    return ( <section className={styles.hero}>
        <div className={`container ${styles.container}`}>
            <div className={styles.content}>
                <img src="/images/web/logo-v2-6.svg" className={styles.logo} alt="bevetu logo" />
                <p className={styles.subtitle}>{subtitle}</p>
                <h1 className={styles.title}>{title}</h1>
                <p className={styles.txt}>{txt}</p>
                <Btn onClick={() => setVideoTrigger()}>{btn.txt}</Btn>
            </div>
            <div className={styles.videowrapper}>
                <Video />
            </div>
        </div>
        {showVideo && <div className={styles.fixedVideoWrapper}>
            <div className={styles.innerwrapper}>
                <div className={styles.video}>
                    <button onClick={() => setVideoTrigger()} className={styles.close}>
                        Close X
                    </button>
                    <video width="320" height="240" controls>
                        <source src="/video/video-2.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>    
                </div>
            </div>
        </div>}
    </section> );
}
 
export default Hero;