import React from "react";
import styles from "./Social.module.scss";
import List from "./List";
import Card from "./Card";

interface SocialProps {}
interface SocialState {
    showCreate: boolean,
    cards: {
        profile: string;
        title: string;
        time: string;
        src: string;
        liked: string;
    }[];
}

class Social extends React.Component<SocialProps, SocialState> {
    constructor(props: SocialProps) {
        super(props);
        this.state = {  
            showCreate: false,
            cards: [],
        };
        this.showCreatePlatform = this.showCreatePlatform.bind(this);
    }

    showCreatePlatform() {
        this.setState({
            showCreate: !this.state.showCreate
        })
    }

    componentDidMount() {
        fetch('/demoData/social.json')
            .then(response => response.json())
            .then(data => this.setState({ cards: data.items }))
            .catch(error => console.error('Error fetching data:', error));
    }

    render() {
        return (
            <div className={styles.social}>
                {this.state.showCreate && <div className={styles.createPlatform}>
                    <div className={styles.createPlatform__wrapper}>
                        <div className={styles.createPlatform__wrapper__head}>
                            Create
                            <button type="button" className={styles.createPlatform__wrapper__head__close} onClick={this.showCreatePlatform}>
                                <img src="/images/ico/x-lg.svg" />
                            </button>
                        </div>
                        <div className={styles.createPlatform__wrapper__body}>
                            <button type="button" className={styles.createPlatform__wrapper__body__button}>
                                <img src='/images/ico/image.svg' className={styles.createPlatform__wrapper__body__button__img} alt="" />
                                Post Image
                            </button>
                            <button type="button" className={styles.createPlatform__wrapper__body__button}>
                                <img src='/images/ico/camera-reels.svg' className={styles.createPlatform__wrapper__body__button__img} alt="" />
                                Post Video
                            </button>
                            <button type="button" className={styles.createPlatform__wrapper__body__button}>
                                <img src='/images/ico/patch-question.svg' className={styles.createPlatform__wrapper__body__button__img} alt="" />
                                Question Post
                            </button>
                        </div>
                    </div>
                </div>}                
                <button className={styles.add} onClick={this.showCreatePlatform}>
                    <img src="/images/ico/plus-lg.svg" alt="add" />
                </button>
                <div className={styles.top}>
                    <p className={styles.greeting}>Hello, Wilson!</p>
                    <h1 className={styles.title}>The Latest</h1>
                    <div className={styles.search}>
                        <img src="/images/ico/search.svg" />
                    </div>
                    <div className={styles.notice}>
                        <img src="/images/notice-white.svg" />
                    </div>
                </div>
                <div className={styles.lists}>
                    <List src="/images/wilson-wong.jpeg" bgcolor="#32c5ff" name="You" />
                    <List src="/images/profile/Bella.png" bgcolor="#f4c698" name="Bella" />
                    <List src="/images/profile/coco.png" bgcolor="#e1f7ff" name="CoCo" />
                    <List src="/images/profile/Luna.png" bgcolor="#f4f4f4" name="Luna" />
                    <List src="/images/profile/max.png" bgcolor="#333333" name="Max" />
                    <List src="/images/profile/Oliver.png" bgcolor="#ff0000" name="Oliver" />
                </div>
                <div className={styles.cards}>
                    {this.state.cards.map((card, index) => (
                        <Card
                            key={index}
                            profile={card.profile}
                            title={card.title}
                            time={card.time}
                            src={card.src}
                            liked={card.liked}
                        />
                    ))}
                </div>
                {/* <img className={styles.bg} src="/images/social.png" /> */}
            </div>
        );
    }
}

export default Social;
