import React from "react";
import styles from './Input.module.scss';

interface InputProps {
    label?: string;
    id?: string;
    placeholder?: string;
    type?: string;
    valueTxt?: string;
    name?: string;
    remark?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    left?: boolean;
}
 
interface InputState {
    
}
 
class Input extends React.Component<InputProps, InputState>  {
    render() { 
        const { placeholder, label, name, type="txt", valueTxt, onChange, left=false, id,  remark } = this.props;
        
        return ( <div className={styles.input}>
            {label && <label 
                className={`${styles.input__label}`} 
                htmlFor={id}>{label}:</label>}
            <input 
                type={type} 
                className={`${styles.input__input} ${left?styles.left:null}`} 
                placeholder={placeholder} 
                name={name} 
                value={valueTxt} 
                onChange={onChange}
                id={id}
            />
            {remark && <small>{remark}</small>}
        </div> );
    }
}
 
export default Input;