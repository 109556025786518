import React, {FC} from "react";
import styles from './Social.module.scss';

interface CardProps {
    profile?: string;
    title?: string;
    time?: string;
    src?: string;
    liked?: string;
}
 
const Card: FC<CardProps> = props => {
    const {profile,title,time,src,liked} = props;
    return ( <div className={styles.card}>
        <div className={styles.card__top}>
            <div className={styles.card__top__img}>
                <div className={styles.card__top__img__inner}>
                    <img src={profile} />
                </div>
            </div>    
            <div className={styles.card__top__txt}>
                <div className={styles.card__top__txt__title}>
                    {title}
                </div>
                <div className={styles.card__top__txt__time}>
                    {time}
                </div>
            </div>
            <div className={styles.card__top__tool}>
                <img src="/images/ico/three-dots.svg"/>
            </div>
        </div>
        <div className={styles.card__img}>
            <img src={src} />
        </div>
        <div className={styles.card__btm}>   
            <div className={styles.card__btm__icos}>
                <img src="/images/ico/heart.svg" />
                <img src="/images/ico/comments.svg" />
                <img src="/images/ico/send.svg" />
                <img src="/images/ico/save.svg" className={styles['card__btm__icos__ico--last']} />
            </div>
            <div className={styles.card__btm__info}>                
                <div className={styles.card__btm__info__img}>
                    <span><img src="/images/profile/Bella.png" /></span>
                    <span><img src="/images/profile/coco.png" /></span>
                    <span><img src="/images/profile/Luna.png" /></span>
                    <span><img src="/images/profile/max.png" /></span>
                    <span><img src="/images/profile/Oliver.png" /></span>
                </div>
                <div className={styles.card__btm__info__liked}>Liked <b>{liked}</b> and <b>12k more</b></div>
            </div>
        </div>
    </div> );
}
 
export default Card;