import { FormData } from './Types';

const data: FormData =  {
    laboratory: 'IDEXX',
    species: 'Canine',
    vetClinicName: 'AGI-VET',
    receiptDate: '6/5/2023',
    idexxSerivces: 'ProCyte One Haematology Analyser, Catalyst One Chemistry Analyser',
    petName: 'BEYONCE KAM',
    petOwner: 'KAM',
    vetClinicAddress: 'Shearer Way Manchester, Greater Manchester M27 8WA',
    vetName: 'Agnieszka Hanszke',
    resultDate: '6/5/2023',
    haematologyResultComment: 'Anemia without reticulocytosis - Likely non-regenerative anemia; consider pre-regenerative anemia.\nIncreased MCHC or MCH\nConsider hemolysis (including sample collection/handling), lipemia, and Heinz bodies.',
    chemistryTable: [
        { testItem: 'Glucose', resultValue: '5.99', referenceValue: '4.11 - 7.95 mmol/L' },
        { testItem: 'IDEXX SDMA', resultValue: undefined, referenceValue: '0 - 14 ug/dL' },
        { testItem: 'Creatinine', resultValue: '178', referenceValue: '44 - 159 umol/L' },
        { testItem: 'Urea', resultValue: '14.4', referenceValue: '2.5 - 9.6 mmol/L' },
        { testItem: 'BUN: Creatinine Ratio', resultValue: '20', referenceValue: undefined },
        { testItem: 'Sodium', resultValue: '133', referenceValue: '144 - 160 mmol/L' },
        { testItem: 'Potassium', resultValue: '5.0', referenceValue: '3.5 - 5.8 mmol/L' },
        { testItem: 'Na: K Ratio', resultValue: '26', referenceValue: undefined },
        { testItem: 'Chloride', resultValue: '97', referenceValue: '109 - 122 mmol/L' },
        { testItem: 'Total Protein', resultValue: '70', referenceValue: '52 - 82 g/L' },
        { testItem: 'Albumin', resultValue: '33', referenceValue: '23 - 40 g/L' },
        { testItem: 'Globulin', resultValue: '37', referenceValue: '25 - 45 g/L' },
        { testItem: 'Albumin: Globulin Ratio', resultValue: '0.9', referenceValue: undefined },
    ],
    haematologyTable: [
        { testItem: 'RBC', resultValue: '5.37', referenceValue: '5.65 - 8.87 x10^12/L' },
        { testItem: 'Haematocrit', resultValue: '0.341', referenceValue: '0.373 - 0.617 L/L' },
        { testItem: 'Haemoglobin', resultValue: '143', referenceValue: '131 - 205 g/L' },
        { testItem: 'MCV', resultValue: '63.4', referenceValue: '61.6 - 73.5 fL' },
        { testItem: 'MCH', resultValue: '26.7', referenceValue: '21.2 - 25.9 pg' },
        { testItem: 'MCHC', resultValue: '421', referenceValue: '320 - 379 g/L' },
        { testItem: 'RDW', resultValue: '14.4', referenceValue: '13.6 - 21.7 %' },
        { testItem: '% Reticulocyte', resultValue: '0.4', referenceValue: '%' },
        { testItem: 'Reticulocytes', resultValue: '21.4', referenceValue: '10.0 - 110.0 K/uL' },
        { testItem: 'WBC', resultValue: '12.50', referenceValue: '5.05 - 16.76 x10^9/L' },
        { testItem: '% Neutrophils', resultValue: '70.9', referenceValue: '%' },
        { testItem: '% Lymphocytes', resultValue: '13.3', referenceValue: '%' },
        { testItem: '% Monocytes', resultValue: '8.4', referenceValue: '%' },
        { testItem: '% Eosinophils', resultValue: '7.4', referenceValue: '%' },
        { testItem: '% Basophils', resultValue: '0.1', referenceValue: '%' },
        { testItem: 'Neutrophils', resultValue: '8.87', referenceValue: '2.95 - 11.64 x10^9/L' },
        { testItem: 'Lymphocytes', resultValue: '1.66', referenceValue: '1.05 - 5.10 x10^9/L' },
        { testItem: 'Monocytes', resultValue: '1.05', referenceValue: '0.16 - 1.12 x10^9/L' },
        { testItem: 'Eosinophils', resultValue: '0.93', referenceValue: '0.06 - 1.23 x10^9/L' },
        { testItem: 'Basophils', resultValue: '0.01', referenceValue: '0.00 - 0.10 x10^9/L' },
        { testItem: 'Platelets', resultValue: '416', referenceValue: '148 - 484 x10^9/L' },
        { testItem: 'PDW', resultValue: '17.2', referenceValue: '9.1 - 19.4 fL' },
        { testItem: 'MPV', resultValue: '13.5', referenceValue: '8.7 - 13.2 fL' },
        { testItem: 'Plateletcrit', resultValue: '0.56', referenceValue: '0.14 - 0.46 %' },
    ],
    breed: '',
    gender: '',
    age: '',
    patientId: '',
    vetAccount: '',
    labId: '',
    orderId: ''
}

export default data;