import React from 'react';
import Header from '../../../../components/Web/Header/Header';

import { Helmet } from 'react-helmet';

import styles from "./social.module.scss";
import Details from '../../../../components/Web/Details/Details';
import Premium from '../../../../components/Web/Premium/Premium';
import Social from '../../../../components/Web/Social/Social';
import WorkingWith from '../../../../components/Web/WorkingWith/WorkingWith';
import TxtImg from '../../../../components/TxtImg/TxtImg';
import Footer from '../../../../components/Web/Footer/Footer';

// content
import header from '../../../../content/web/header';
import details from '../../../../content/web/social/details';
import footer from '../../../../content/web/footer';
import premiumHm from '../../../../content/web/home/premium';
import socialContent from '../../../../content/web/social';
import seo from '../../../../content/web/seo';
import txtImg from '../../../../content/web/social/txtImg';
import txtImg2 from '../../../../content/web/social/txtImg2';

interface IndexProps {}
 
const Index: React.FC<IndexProps> = () => {
    const {title,description,keywords,url,type} = seo;
    return (
      <div className={styles.index}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content={type} />
        </Helmet>
        <Header nav={header.nav} contact={header.contact} />
        <Social content={socialContent} bgColor='#e1f7ff' paddingTop='2rem' />
        <TxtImg content={txtImg}/>
        <Details content={details} col={2}/>
        <TxtImg content={txtImg2}/>
        <Premium content={premiumHm} />
        <WorkingWith />
        <Footer nav={header.nav} copy={footer.copy} />
      </div>
    );
}
 
export default Index;