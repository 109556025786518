import React from "react";
import styles from "./Notice.module.scss";

interface NoticeProps {}
 
const Notice: React.FunctionComponent<NoticeProps> = () => {
    return ( <div className={styles.notice}>
        <img src="/images/notice/MobileHeader.png" alt="notice 1" />
        <img src="/images/notice/Tabs.png" alt="notice 1" />
        <img src="/images/notice/Mobile.png" alt="notice 1" />
    </div> );
}
 
export default Notice;