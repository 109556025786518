// src/RegistrationForm.tsx
import React, { useState, ChangeEvent, FormEvent } from 'react';
import styles from "./RegistrationForm.module.scss";
import Input from '../../components/Input/Input';
import Btn from '../../components/Btn/Btn';

interface Owner {
  fullName: string;
  email: string;
  phone: string;
  address: string;
  image: File | null;
}

interface Pet {
  petName: string;
  species: string;
  breed: string;
  gender: string;
  birthDate: string;
  color: string;
  clinic: string;
  vetName: string;
  conditions: string;
  medications: string;
  allergies: string;
  microchip: string;
  spayedNeutered: string;
  image: File | null;
}

const RegistrationForm: React.FC = () => {
  const [owner, setOwner] = useState<Owner>({
    fullName: 'Chan Tai Man',
    email: 'chan_tai_man@gmail.com',
    phone: '+447442232399',
    address: '',
    image: null,
  });

  const [pet, setPet] = useState<Pet>({
    petName: '',
    species: '',
    breed: '',
    gender: '',
    birthDate: '',
    color: '',
    clinic: '',
    vetName: '',
    conditions: '',
    medications: '',
    allergies: '',
    microchip: '',
    spayedNeutered: '',
    image: null,
  });

  const handleOwnerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOwner({ ...owner, [name]: value });
  };

  const handleOwnerImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setOwner({ ...owner, image: e.target.files[0] });
    }
  };

  const handlePetChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPet({ ...pet, [name]: value });
  };

  const handlePetImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setPet({ ...pet, image: e.target.files[0] });
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log('Owner:', owner);
    console.log('Pet:', pet);
    // Here you can handle form submission, e.g., send the data to a backend server
  };

  return (
    <form onSubmit={handleSubmit} className={styles.registrationForm}>
        <p>Tell us about you and your pet.</p>
        <h2>Owner Information</h2>
        <Input label="Full Name" id="full_name" placeholder="Full name" type="text" valueTxt={owner.fullName} name="fullName" onChange={handleOwnerChange} />
        <Input label="Email Address" id="email_address" placeholder="Email address" type="email" valueTxt={owner.email} name="email" onChange={handleOwnerChange} />
        <Input label="Phone Number" id="phone_number" placeholder="Phone number" type="tel" valueTxt={owner.phone} name="phone" onChange={handleOwnerChange} />
        <Input label="Address" id="address" placeholder="Address" type="text" valueTxt={owner.address} name="address" onChange={handleOwnerChange} />
        <Input label="Owner Image" id="owner_image" placeholder="" type="file" valueTxt={undefined} name="ownerImage" onChange={handleOwnerImageChange} />

        <h2>Pet Information</h2>
        <Input label="Pet's Name" id="pet_name" placeholder="Pet's name" type="text" valueTxt={pet.petName} name="petName" onChange={handlePetChange} />
        <Input label="Species" id="species" placeholder="Species" type="text" valueTxt={pet.species} name="species" onChange={handlePetChange} />
        <Input label="Breed" id="breed" placeholder="Breed" type="text" valueTxt={pet.breed} name="breed" onChange={handlePetChange} />
        <Input label="Gender" id="gender" placeholder="Gender" type="text" valueTxt={pet.gender} name="gender" onChange={handlePetChange} />
        <Input label="Date of Birth" id="birth_date" placeholder="Date of birth" type="text" valueTxt={pet.birthDate} name="birthDate" onChange={handlePetChange} />
        <Input label="Color/Markings" id="color_markings" placeholder="Color/markings" type="text" valueTxt={pet.color} name="color" onChange={handlePetChange} />
        <Input label="Veterinary Clinic/Hospital Name" id="clinic_name" placeholder="Clinic name" type="text" valueTxt={pet.clinic} name="clinic" onChange={handlePetChange} />
        <Input label="Primary Veterinarian’s Name" id="vet_name" placeholder="Vet name" type="text" valueTxt={pet.vetName} name="vetName" onChange={handlePetChange} />
        <Input label="Medical Conditions" id="conditions" placeholder="Conditions" type="text" valueTxt={pet.conditions} name="conditions" onChange={handlePetChange} />
        <Input label="Medications" id="medications" placeholder="Medications" type="text" valueTxt={pet.medications} name="medications" onChange={handlePetChange} />
        <Input label="Allergies" id="allergies" placeholder="Allergies" type="text" valueTxt={pet.allergies} name="allergies" onChange={handlePetChange} />
        <Input label="Microchip Number" id="microchip" placeholder="Microchip number" type="text" valueTxt={pet.microchip} name="microchip" onChange={handlePetChange} />
        <Input label="Spayed/Neutered" id="spayed_neutered" placeholder="Spayed/neutered" type="text" valueTxt={pet.spayedNeutered} name="spayedNeutered" onChange={handlePetChange} />
        <Input label="Pet Image" id="pet_image" placeholder="" type="file" valueTxt={undefined} name="petImage" onChange={handlePetImageChange} />
        <div className={styles.btnWrapper}>
            <Btn href="/app/">Register</Btn>
        </div>
    </form>
  );
};

export default RegistrationForm;
