import React, { useState } from "react";
import styles from "./Question.module.scss";
import Head from "../../components/Head/Head";
import PageBtn from "../../components/PageBtn/PageBtn";

interface QuestionProps {}

const Question: React.FunctionComponent<QuestionProps> = () => {
    const [questionText, setQuestionText] = useState<string>("");
    const characterLimit = 80;

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (event.target.value.length <= characterLimit) {
            setQuestionText(event.target.value);
        }
    };

    // Determine if the character limit has been reached
    const isLimitReached = questionText.length >= characterLimit;

    return (
        <div className={`${styles.question} ${isLimitReached ? styles.error : ""}`}>
            <Head title="Post a Question" />
            <div className={styles.notice}>
                <p className={styles.notice__txt}>
                    Post a question to other pet lovers, and collect responses for 24 hours.
                </p>
            </div>
            <div className={styles.questionwrapper}>
                <p className={styles.questionwrapper__title}>Ask a question</p>
                <textarea 
                    value={questionText}
                    onChange={handleTextChange}
                    placeholder="Post your question."
                ></textarea>
                <div className={styles.word__limit}>
                    {questionText.length}/{characterLimit}
                </div>
            </div>
            <PageBtn />
        </div>
    );
};

export default Question;
