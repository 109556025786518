import React from "react";
import Btn from "../../components/Btn/Btn";
import Input from "../../components/Input/Input";
import styles from "./Routine.module.scss";

interface RoutineProps {}
 
const Routine: React.FunctionComponent<RoutineProps> = () => {
    return ( <div className={styles.routine}>
        <div className={styles.inputwrapper}>
            <Input label="Full Name" id="full_name" placeholder="Search location" type="text" name="fullName" left={true} />
        </div>
        <div className={styles.imgwrapper}>
            <img src="/images/routine/map.png" alt="map" className={styles.img}/>
        </div>
        <div className={styles.btnwrapper}>
            <div className={styles.detailswrapper}>
                <img src="/images/routine/details.png" className={styles.details} alt="path picker" />
            </div>
            <div className={styles.btninwrapper}>
                <Btn>Add to calendar</Btn>
            </div>
        </div>
    </div> );
}
 
export default Routine;