import React from "react";
import styles from "./ReportForm.module.scss";
import demoData from "../../demoData/demo_data.json";
import Input from "../../components/Input/Input";
import Btn from "../../components/Btn/Btn";
import SuperHr from "../../components/SuperHr/SuperHr";

interface BoundingRegion {
    pageNumber: number;
    polygon: number[];
}

interface Span {
    offset: number;
    length: number;
}

interface Field {
    type: string;
    valueString?: string;
    content: string;
    boundingRegions: BoundingRegion[];
    confidence: number;
    spans: Span[];
}

interface Document {
    fields: {
        [key: string]: Field;
    };
}

interface AnalyzeResult {
    documents: Document[];
}

interface DemoData {
    analyzeResult: AnalyzeResult;
}
  
interface ReportFormProps {}
interface ReportFormState {}

class ReportForm extends React.Component<ReportFormProps, ReportFormState> {
    massageData = () => {
      const data: { label: string; content: string }[] = [];
      const documents = demoData.analyzeResult.documents[0].fields as unknown as { [key: string]: Field };
      const keys = Object.keys(documents);
  
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        data.push({
          label: element,
          content: documents[element].content, // Access the content property dynamically
        });
      }
      return data;
    };

    addSpaceBeforeCapitalLetters = (str:string) => {
        return str.replace(/([A-Z])/g, ' $1').trim();
    }
  
    render() {
      const data = this.massageData();
  
      return (
        <div className={styles.reportForm}>
          <div className={styles.head}>
            <h1 className={styles.title}>Result</h1>
          </div>
          <div className={styles.body}>
            <p>This is the result got from report. Before analysis with AI, please check the value is valid.</p>
            <SuperHr />
            {/* Render the data here if needed */}
            {data.map((item, index) => (
              <React.Fragment key={`report-input-${index}`}>
                {item.content && <>
                    <label htmlFor="item.label" className={styles.label}>
                        <strong>{this.addSpaceBeforeCapitalLetters(item.label)}:</strong>
                    </label>
                    <div className={styles.input}>
                        <Input 
                            id={item.label} 
                            valueTxt={item.content} 
                            left={true} 
                            onChange={() => {}} 
                        />
                    </div>
                </>
                }
              </React.Fragment>
            ))}
            <SuperHr />
            <Btn>Submit</Btn>
          </div>
        </div>
      );
    }
  }
  
  export default ReportForm;