import React, { useState } from "react";
import styles from "./Post.module.scss";
import Head from "../../components/Head/Head";
import PageBtn from "../../components/PageBtn/PageBtn";

interface PostProps {
    preUploadedImageUrl?: string; // Optional prop for the pre-uploaded image URL
}

const Post: React.FunctionComponent<PostProps> = ({ preUploadedImageUrl }) => {
    const [imagePreview, setImagePreview] = useState<string | null>(preUploadedImageUrl || null);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadClick = () => {
        const fileInput = document.getElementById("fileInput") as HTMLInputElement;
        if (fileInput) {
            fileInput.click();
        }
    };

    return (
        <div className={styles.post}>
            <Head title="Create Post" />
            <div className={styles.body}>
                <div className={styles.upload} onClick={handleUploadClick}>
                    {imagePreview ? (
                        <img src={imagePreview} className={styles.upload__ico} alt="Upload Preview" />
                    ) : (
                        <>
                            <img src="/images/ico/camera.svg" className={styles.upload__ico} alt="Camera Icon" />
                            <p className={styles.upload__txt}>Add Photo</p>
                        </>
                    )}
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                        accept="image/*"
                    />
                </div>
                <div className={styles.your_mind}>
                    <p className={styles.your_mind__txt}>What's on your mind?</p>
                    <textarea name="" id="" placeholder="White a caption." />
                </div>
                <div className={styles.who}>
                    <p className={styles.who__title}>Who is in the image?</p>
                    <div className={styles.who__list}>
                        <div className={styles.who__list__item}>
                            <div className={styles.who__list__item__imgwrapper}>
                                <img src="/images/profile/Bella.png" className={styles.who__list__item__imgwrapper__img} alt="Person" />
                            </div>
                            <p className={styles.who__list__item__txt}>Boyonce</p>
                        </div>
                    </div>
                </div>
            </div>
            <PageBtn />
        </div>
    );
};

export default Post;
