import React from "react";
import styles from "./DailyTask.module.scss";
import Task from "./Task";
import PetHealthChecklist from "./PetHealthChecklist"; // Assuming you have a separate PetHealthChecklist component

interface DailyTaskProps {}
interface DailyTaskState {
    activeTasks: { [key: string]: boolean };
}

class DailyTask extends React.Component<DailyTaskProps, DailyTaskState> {
    constructor(props: DailyTaskProps) {
        super(props);
        this.state = {
            activeTasks: {
                Breakfast: false,
                Lunch: false,
                Dinner: false,
                Water: false,
                Walk: false,
                Pet: false,
            },
        };
    }

    handleTaskClick = (taskName: string) => {
        this.setState((prevState) => ({
            activeTasks: {
                ...prevState.activeTasks,
                [taskName]: !prevState.activeTasks[taskName],
            },
        }));
    };

    render() {
        const { activeTasks } = this.state;
        return (
            <div className={styles.dailyTask}>
                <div className={styles.innerwrapper}>
                    <h2 className={styles.title}>Daily Tasks</h2>
                </div>

                <PetHealthChecklist /> {/* Include the PetHealthChecklist component */}
            </div>
        );
    }
}

export default DailyTask;
