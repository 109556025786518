import React from "react";
import styles from "./Head.module.scss";
import Btn from "../../components/Btn/Btn";

interface HeadProps {}
interface HeadState {}

class Head extends React.Component<HeadProps, HeadState> {
    render() { 
        return ( <div className={styles.head}>
            <div className={styles.wrapper}>
                {/* <div className="profile">
                    <img src="/images/coco-profile-pic.png" />
                </div> */}
                <h1 className={styles.title}>BEYONCE KAM’s health report</h1>
                <p className={styles.txt}>Name: BEYONCE KAM</p>
                <p className={styles.txt}>Species: Dog</p>
                <p className={styles.txt}>Breed: Labrador Retriever</p>
                <p className={styles.txt}>Age: 3 years</p>
                <p className={styles.txt}>Sex: Female</p>
                <p className={styles.txt}>Weight: 28 kg</p>
                <p className={styles.txt}>Owner: Ms. Emily Johnson</p>
                <Btn>Find Nearby Vet</Btn>
            </div>
        </div> );
    }
}
 
export default Head;