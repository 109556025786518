import React, { FunctionComponent } from "react";
import styles from './PetDetails.module.scss';
import DailyTask from "../../components/DailyTask/DailyTask";

interface DetailsProps {
    closeClick?: () => void;
}

const Details: FunctionComponent<DetailsProps> = props => {
    const { closeClick } = props;
    return (
        <div className={styles.details}>
            {/* <button className={styles.details__back} onClick={closeClick}>Back</button> */}
            <div className={styles.top}>
                {/* <div className={styles.name}>CoCo
                    <div className={styles.pt}>400pt</div>
                </div> */}
                <div className={styles.btn__wrapper}>
                    <div>
                        <div className={styles.age}>
                            <div className={styles.age__ico}>
                                <img src="/images/ico/activity.svg" />
                            </div>
                            <div className={styles.age__txt}>
                                <div className={styles.age__txt__title}>
                                    Age
                                </div>
                                <div className={styles.age__txt__number}>
                                    5
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={styles.weight}>
                            <div className={styles.weight__ico}>
                                <img src="/images/ico/backpack-fill.svg" />
                            </div>
                            <div className={styles.weight__txt}>
                                <div className={styles.weight__txt__title}>
                                    Weight (kg)
                                </div>
                                <div className={styles.weight__txt__number}>
                                    15
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.calendar}>
                <img src="/images/Header.png" />
                <img src="/images/Days.png" />
            </div>
            <DailyTask></DailyTask>
        </div>);
}

export default Details;