import React from "react";
import styles from "./Header.module.scss";
import Btn from "../Btn/Btn";
import { handleScrollToPosition } from "../../../utils/handleScrollToPosition";
import { HeaderProps, NavItem } from "./type";

const Header: React.FunctionComponent<HeaderProps> = props => {
    const {nav, contact} = props

    return ( <header className={styles.header}>
        <div className={`container ${styles.container}`}>
            <a href="/" className={styles.home}><img src={`/images/web/logo-v2-6.svg`} /></a>
            <nav className={styles.nav}>
                {/* {nav && nav.map((item:NavItem, i) => {
                    const {id, txt} = item
                    return (<a href="#" key={`header__nav__${i}`} onClick={e => handleScrollToPosition(e, id)}>{txt}</a>);
                })} */}
                <div className={styles.submenu}>
                    <div className={styles.submenu__head}>Features</div>
                    <div className={styles.submenu__body}>
                        <a href="/features/records" className={styles.submenu__body__link}>Health Records</a>
                        <a href="/features/social" className={styles.submenu__body__link}>Social Network</a>
                        <a href="/features/premium" className={styles.submenu__body__link}>Flockr Premium</a>
                    </div>
                </div>
            </nav>
            <div className={styles.btn_wrapper}>
                <Btn href="mailto:marketing@vowix.co">{contact.txt}</Btn>
            </div>
        </div>
    </header> );
}
 
export default Header;
