import React, { useEffect } from 'react';
import styles from './Home.module.scss';
import PetCard from '../../components/PetCard/PetCard';
import SuperHr from '../../components/SuperHr/SuperHr';
import Promotion from '../../components/Promotion/Promotion';
import CommonHead from '../../components/CommonHead/CommonHead';
import NearbyVet from '../../components/NearbyVet/NearbyVet';
import ShortCuts from '../../components/ShortCuts/ShortCuts';
import Details from '../PetDetails/Details';

import HomeData from '../../content/app/Home';

const Home: React.FC = () => {
  const data = HomeData.shortcuts;

  useEffect(() => {
    setTimeout(() => {
      document.getElementsByClassName('wrapper')[0].scrollTo({ top: 0, behavior: 'smooth' });
      console.log("Scrolled to top");
    }, 100);
    
  }, []);

  return (
    <div className={styles.home}>
      <CommonHead />
      <ShortCuts data={data} />
      <Details />
      {/* pet start */}
      <div className={styles.pets}>
        <div className={styles.pets__top}>
          <div>Family member:</div>
          <div>
            <a href="#">+ Add</a>
          </div>
        </div>
        <div className={styles.pets__btm}>
          <PetCard bgColor="#EDB507" src="/images/profile/coco.png" name="CoCo" type="Dog" active={true} />
          <PetCard bgColor="#E06B2D" src="/images/profile/Bella.png" name="Bella" type="Dog" />
          <PetCard bgColor="#EC9BA7" src="/images/profile/max.png" name="Max" type="Dog" />
          <PetCard bgColor='#70BBEE' src="/images/profile/Oliver.png" name="Oliver" type="Cat" />
          <PetCard bgColor='#535D82' src="/images/profile/Luna.png" name="Luna" type="Cat" />
        </div>
      </div>
      {/* pet end */}
      {/* <DailyTask /> */}
      <NearbyVet />
      <SuperHr />
      <Promotion href="#" />

      
    </div>
  );
};

export default Home;
