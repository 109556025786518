const details = {
    title: "A super app for your pet",
    txt: "Awesome features to help your pet throughout its life!",
    nav: [
        {
            src: "https://flockr.social/icons/home.svg",
            title: "Feed",
            txt: "Interact with pets from all over the world! Post the best photos of your pet and distribute likes and comments!"
        },
        {
            src: "https://flockr.social/icons/notification.svg",
            title: "Notifications",
            txt: "Did your pet get a like? You find out. New follower? Also! Stay on top of your pet's profile with notifications"
        },
        {
            src: "https://flockr.social/icons/profile.svg",
            title: "Profile",
            txt: "All photos, organized, in a beautiful profile! In addition, we show the breed and species along with your pet's biography."
        },
        {
            src: "https://flockr.social/icons/pet.svg",
            title: "Walkings",
            txt: "The walks with your pet are now even more fun, track the time and distance of the walk through Flockr and share it with your friends in the app and on all social networks!"
        },
    ]
}

export default details;
