import React from "react";
import styles from "./SocialLogin.module.scss";
import { Link } from "react-router-dom";

interface SocialLoginProps {
    src?: string;
    children: React.ReactNode;
    onClick?: () => void;
    href: string;
}
 
const SocialLogin: React.FunctionComponent<SocialLoginProps> = props => {
    const {src,children,onClick,href} = props;
    return ( <Link to={href} className={styles.socialLogin} onClick={onClick}>
        <span className={styles.socialLogin__ico}>
            <img src={src} alt="Social login Link icon" />
        </span>
        <span className={styles.socialLogin__txt}>{children}</span>        
    </Link> );
}
 
export default SocialLogin;