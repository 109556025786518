import React, { useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import demoData from './demo_data2';
import { FormData } from './Types';
import Input from '../../components/Input/Input';
import Btn from '../../components/Btn/Btn';
import Accordion from '../../components/Accordion/Accordion';
import styles from './Upload.module.scss';

const initialFormData: FormData = demoData;

interface FormProps {
    onSuccess: (e:any) => void;
}

const Form: React.FC<FormProps> = props => {
    const { onSuccess } = props;
    const [formData, setFormData] = useState(initialFormData);
    
    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData({
                ...formData,
                [name]: value,
            }
        );
    };

    const handleNestedChange = (e: ChangeEvent<HTMLInputElement>, index: number, tableName: 'chemistryTable' | 'haematologyTable') => {
        const { name, value } = e.target;
        const updatedTable = formData[tableName].map((item, i) =>
          i === index ? { ...item, [name]: value } : item
        );
        setFormData({
          ...formData,
          [tableName]: updatedTable,
        });
    };

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        axios
            .post("http://localhost:3000/scanner/diagnosis", formData)
            .then(response => { 
                console.log('Data submitted successfully:', response.data);
                const { content } = response.data
                onSuccess(content)
            })
            .catch(error => {
                console.error('Error submitting data:', error);
            });
    };

    return (
        <div className={styles.form}>
            <p>Please check your data, then Doctor P can help you to know more about this report.</p>
            <form onSubmit={handleSubmit}>
                <Input left={true} label='Laboratory' id="Laboratory" type="text" name="laboratory" valueTxt={formData.laboratory} onChange={handleChange} />
                <Input left={true} label='Species' id="Species" type="text" name="species" valueTxt={formData.species} onChange={handleChange} />
                <Input left={true} label='Vet Clinic Name' id="VetClinicName" type="text" name="vetClinicName" valueTxt={formData.vetClinicName} onChange={handleChange} />
                <Input left={true} label='Receipt Date' id="ReceiptDate" type="text" name="receiptDate" valueTxt={formData.receiptDate} onChange={handleChange} />
                <Input left={true} label='IDEXX Services' id="IDEXXServices" type="text" name="idexxSerivces" valueTxt={formData.idexxSerivces} onChange={handleChange} />
                <Input left={true} label='Pet Name' id="PetName" type="text" name="petName" valueTxt={formData.petName} onChange={handleChange} />
                <Input left={true} label='Pet Owner' id="PetOwner" type="text" name="petOwner" valueTxt={formData.petOwner} onChange={handleChange} />
                <Input left={true} label='Vet Clinic Address' id="VetClinicAddress" type="text" name="vetClinicAddress" valueTxt={formData.vetClinicAddress} onChange={handleChange} />
                <Input left={true} label='Vet Name' id="VetName" type="text" name="vetName" valueTxt={formData.vetName} onChange={handleChange} />
                <Input left={true} label='Result Date' id="ResultDate" type="text" name="resultDate" valueTxt={formData.resultDate} onChange={handleChange} />
                <Input left={true} label='Haematology Result Comment' id="HaematologyResultComment" type="text" name="haematologyResultComment" valueTxt={formData.haematologyResultComment} onChange={handleChange} />
                <Input left={true} label='Breed' id="Breed" type="text" name="breed" valueTxt={formData.breed} onChange={handleChange} />
                <Input left={true} label='Gender' id="Gender" type="text" name="gender" valueTxt={formData.gender} onChange={handleChange} />
                <Input left={true} label='Age' id="Age" type="text" name="age" valueTxt={formData.age} onChange={handleChange} />
                <Input left={true} label='Patient ID' id="PatientId" type="text" name="patientId" valueTxt={formData.patientId} onChange={handleChange} />
                <Input left={true} label='Vet Account' id="VetAccount" type="text" name="vetAccount" valueTxt={formData.vetAccount} onChange={handleChange} />
                <Input left={true} label='Lab ID' id="LabId" type="text" name="labId" valueTxt={formData.labId} onChange={handleChange} />
                <Input left={true} label='Order ID' id="OrderId" type="text" name="orderId" valueTxt={formData.orderId} onChange={handleChange} />
                <div className={styles.nest}>
                    <Accordion title="Chemistry Table">
                        {formData.chemistryTable.map((item, index) => (
                            <Input left={true} key={`chemistryTable-${index}`} label={item.testItem} id={`chemistryTable-${index}`} type="text" valueTxt={item.resultValue || ''} onChange={(e) => handleNestedChange(e, index, 'chemistryTable')} remark={`Reference Value: ${item.referenceValue}`}/>
                        ))}
                    </Accordion>
                </div>
                <div className={styles.nest}>
                    <Accordion title="Haematology Table">
                        {formData.haematologyTable.map((item, index) => (
                            <Input left={true} key={`haematologyTable-${index}`} label={item.testItem} id={`haematologyTable-${index}`} type="text" valueTxt={item.resultValue || ''} onChange={(e) => handleNestedChange(e, index, 'haematologyTable')} remark={`Reference Value: ${item.referenceValue}`}/>
                        ))}
                    </Accordion>
                </div>
                <div className={styles['btn-wrapper']}>
                    <Btn type="submit">Submit</Btn>
                </div>
            </form>
        </div>
    );
}

export default Form;
