import React from "react";
import styles from "./Chat.module.scss";
import Conversation from "../../components/Conversation/Conversation";
import Input from "../../components/Input/Input";
import { Link } from "react-router-dom";

interface ChatProps {} 
interface ChatState {} 

class Chat extends React.Component<ChatProps, ChatState> {
    render() { 
        return ( <div className={styles.chat}>
            <div className={styles.chat__top}>
                {/* <button type="button" className={styles.chat__top__back}>
                    <img src="/images/arrow-left-short.svg" />
                </button> */}
                <div className={styles.chat__top__txt}> Let’s talk about <br/> Your pet</div>
            </div>
            <div className={styles.chat__btm}>
                <div className={styles.chat__btm__chat}>
                    <div className={styles.chat__btm__chat__doc}>
                        <Conversation src="/images/doc__small__img.svg" name="Doctor P">
                        Good afternoon, Mr. Chan. How are you and Bella doing today?
                        </Conversation>
                        <Conversation left={false} src="/images/wilson-wong.jpeg" name="Doctor P">
                        Hi, Doctor P. We're doing okay, but Bella has been acting a bit unusual lately, and I'm worried.
                        </Conversation>
                        <Conversation src="/images/doc__small__img.svg" name="Doctor P">
                        I see. What specific changes have you noticed in Bella's behavior?
                        </Conversation>
                        <Conversation left={false} src="/images/wilson-wong.jpeg" name="Doctor P">
                        She’s been lethargic and not eating much. She also seems to be drinking a lot more water than usual.
                        </Conversation>
                        <Conversation src="/images/doc__small__img.svg" name="Doctor P">
                        That does sound concerning. How long has she been showing these symptoms?
                        </Conversation>
                        <Conversation left={false} src="/images/wilson-wong.jpeg" name="Doctor P">
                        It's been about a week. I thought it might just be the heat, but it seems more serious.
                        </Conversation>
                        <Conversation src="/images/doc__small__img.svg" name="Doctor P">
                        Lethargy and changes in appetite or thirst can indicate various issues. Have you noticed any vomiting, diarrhea, or weight changes?
                        </Conversation>
                        <Conversation left={false} src="/images/wilson-wong.jpeg" name="Doctor P">
                        She hasn't vomited or had diarrhea, but she has lost a little weight and is urinating more frequently.
                        </Conversation>
                        <Conversation src="/images/doc__small__img.svg" name="Doctor P">
                        Given her symptoms, we need to consider possibilities like diabetes, kidney issues, or thyroid problems. We'll start with a blood panel and urinalysis.
                        </Conversation>
                        <Conversation left={false} src="/images/wilson-wong.jpeg" name="Doctor P">
                        How soon can we do the tests?
                        </Conversation>
                        <Conversation src="/images/doc__small__img.svg" name="Doctor P">
                        We can start right away. It will take a few minutes to collect the samples, and we should have results within a day or two.
                        </Conversation>
                        <Conversation left={false} src="/images/wilson-wong.jpeg" name="Doctor P">
                        Thank you, Doctor P. Is there anything I can do to make her more comfortable in the meantime?
                        </Conversation>
                        <Conversation src="/images/doc__small__img.svg" name="Doctor P">
                        Ensure she stays hydrated and offer her favorite foods. Keep her in a cool, stress-free environment. For more information on her symptoms and potential conditions, you can read this <Link to={"/report"}>report</Link>.
                        </Conversation>
                    </div>
                </div>
            </div>
            <div className={styles.chat__input}>
                <div className={styles.chat__input__input}>
                    <Input placeholder="Type message..."></Input>
                </div>
                <div className={styles.chat__input__send}>
                    <button type="submit"><img src="/images/send-fill.svg" /></button>
                </div>
            </div>
        </div> );
    }
}
 
export default Chat;