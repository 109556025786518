import React from "react";
import styles from "./Report.module.scss";
import Head from "./Head";
import Body from "./Body";
import { ReplaceMarkers } from "../../utils/replaceMarkers";

interface ReportProps {
    content?: string;
    children?: React.ReactNode;
    withoutHead?: boolean;
}

const Report:React.FC<ReportProps> = (props) => {
    const { content, children, withoutHead } = props;
    return ( <div className={styles.report}>
        {!withoutHead && <Head />}        
        {/* {children?<Body>{children}</Body>:<Body />} */}
        {content?<Body><span dangerouslySetInnerHTML={{ __html: ReplaceMarkers(content) }} /></Body>:<Body />}
    </div> );
}
 
export default Report;