import React, { FC } from "react";
import styles from "./Social.module.scss";

interface ListProps {
    src: string;
    bgcolor: string;
    name: string;
}
 
const List: FC<ListProps> = props => {
    const {src, bgcolor, name} = props
    return ( <div className={styles.list}>
        <div className={styles.list__img} style={{borderColor: bgcolor}}>
            <img src={src} />
        </div>
        <div className={styles.list__name}>{name}</div>
    </div> );
}
 
export default List;