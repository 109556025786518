import React, {FunctionComponent} from "react";
import styles from './PetDetails.module.scss';
import Btn from "../../components/Btn/Btn";

interface HomeProps {
    onClick: () => void;
}
 
const Home: FunctionComponent<HomeProps> = props => {
    const {onClick} = props
    return ( <div className={styles.home}>
        <h1 className={styles.title}>Daily <br/><span>Tasks</span></h1>
        <img src="/images/profile/coco.png" alt="" className={styles.home__img} />
        <div className={styles.home__details}>
            <h2 className={styles.home__details__title}>Take Care of <br/> COCO</h2>
            <p>Make your bonding relationship between <span>you and COCO</span></p>
            <Btn onClick={onClick}>Get Start</Btn>
        </div>
    </div> );
}
 
export default Home;