import React, { FC, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import styles from "./Video.module.scss";
import { Waypoint } from 'react-waypoint';
import { url } from 'inspector';

interface VideoProps {}
 
const Video: FC<VideoProps> = () => {
    const [playing, setPlaying] = useState<boolean>(false)
    const _duration = .8;
    const element001Ref = useRef<HTMLDivElement>(null);
    const element002Ref = useRef<HTMLDivElement>(null);
    const element003Ref = useRef<HTMLDivElement>(null);
    const element004Ref = useRef<HTMLDivElement>(null);
    const element005Ref = useRef<HTMLDivElement>(null);
    const element006Ref = useRef<HTMLDivElement>(null);
    const element007Ref = useRef<HTMLDivElement>(null);
    const element008Ref = useRef<HTMLDivElement>(null);
    const element009Ref = useRef<HTMLDivElement>(null);
    const element010Ref = useRef<HTMLDivElement>(null);
    const element011Ref = useRef<HTMLDivElement>(null);
    const element012Ref = useRef<HTMLDivElement>(null);
    const element013Ref = useRef<HTMLDivElement>(null);
    const element014Ref = useRef<HTMLDivElement>(null);
    const element015Ref = useRef<HTMLDivElement>(null);

    const _handleWaypointEnter = ():void => {
      const tl = gsap.timeline({
        onComplete: () => setPlaying(false)
      });
      if(playing) return;
      setPlaying(true)
      if (element003Ref.current) {
        tl
          .fromTo(element001Ref.current, { x: 100, opacity: 0 }, { duration: _duration, x: 0, opacity: 1 })
          .fromTo(element002Ref.current, { x: -100, opacity: 0 }, { duration: _duration, x:0, opacity: 1 })
          .fromTo(element004Ref.current, { x: 50, y:50, opacity: 0 }, { duration: _duration, x:0, y:0, opacity: 1 })
          .fromTo(element005Ref.current, { x: -100, opacity: 0 }, { duration: _duration, x:0, opacity: 1 })
          .fromTo(element006Ref.current, { x: 50, y:-50, opacity: 0 }, { duration: _duration, x:0, y:0, opacity: 1 })
        //   .to(element007Ref.current, { duration: _duration, opacity: 1 })
        //   .to(element008Ref.current, { duration: _duration, opacity: 1 })
        //   .to(element009Ref.current, { duration: _duration, opacity: 1 })
        //   .to(element010Ref.current, { duration: _duration, opacity: 1 })
        //   .to(element011Ref.current, { duration: _duration, opacity: 1 })
        //   .to(element012Ref.current, { duration: _duration, opacity: 1 })
        //   .to(element013Ref.current, { duration: _duration, opacity: 1 })
        //   .to(element014Ref.current, { duration: _duration, opacity: 1 })
        //   .to(element015Ref.current, { duration: _duration, opacity: 1 })
      }
    }
  
    return (
      <Waypoint
            onEnter={_handleWaypointEnter}
      >
        <div>
          <div className={styles.video}>
              <div ref={element001Ref}><img src="/images/video/001.png" alt="video/001" /></div>
              <div ref={element002Ref}><img src="/images/video/002.png" alt="video/002" /></div>
              <div ref={element003Ref} className={styles.ref3}><img src="/images/video/003.png" alt="video/003" /></div>
              <div ref={element004Ref}><img src="/images/video/004.png" alt="video/004" /></div>
              <div ref={element005Ref}><img src="/images/video/005.png" alt="video/005" /></div>
              <div ref={element006Ref}><img src="/images/video/006.png" alt="video/006" /></div>
              <div ref={element007Ref}><img src="/images/video/007.png" alt="video/007" /></div>
              <div ref={element008Ref}><img src="/images/video/008.png" alt="video/008" /></div>
              <div ref={element009Ref}><img src="/images/video/009.png" alt="video/009" /></div>
              <div ref={element010Ref}><img src="/images/video/010.png" alt="video/010" /></div>
              <div ref={element011Ref}><img src="/images/video/011.png" alt="video/011" /></div>
              <div ref={element012Ref}><img src="/images/video/012.png" alt="video/012" /></div>
              <div ref={element013Ref}><img src="/images/video/013.png" alt="video/013" /></div>
              <div ref={element014Ref}><img src="/images/video/014.png" alt="video/014" /></div>
              <div ref={element015Ref}><img src="/images/video/015.png" alt="video/015" /></div>
          </div>
        </div>
      </Waypoint>
    );
}
 
export default Video;