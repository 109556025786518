import React from "react";
import styles from "./Profile.module.scss";

interface ProfileProps {
    img: string;
    name: string;
    lv: number;
    hidePic: boolean;
}
interface ProfileState {}

class Profile extends React.Component<ProfileProps, ProfileState> {
    render() { 
        const {img,name,lv,hidePic} = this.props;
        return ( <div className={styles.profile}>
                          {!hidePic && 
            <div className={styles.profile__img} style={{backgroundColor: "#EDB507"}}>
                <img src={img} alt={`${name} - profile pic`} className={styles.profile__img__img} />
            </div>
            }
            <div className={styles.profile__txt}>
                <div className={styles.profile__txt__name}>
                    Welcome back. {name}
                </div>
                <div className={styles.profile__txt__lv}>
                    Let's take care <span>COCO</span> Lv. {lv}
                </div>
            </div>
        </div> );
    }
}
 
export default Profile;