import React, { useState } from 'react';
import axios from "axios";
import Form from './Form';
import Report from '../Report/Report';
import styles from './Upload.module.scss';
import Btn from '../../components/Btn/Btn';

const Upload: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePreview, setFilePreview] = useState< string | null>();
    const [fileName, setFileName] = useState<string|null>('');
    const [fileSize, setFileSize] = useState<string|null>('');
    const [fileType, setFileType] = useState<string|null>('');
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [returnContent, setReturnContent] = useState<string | null>('');
    const [showVal, setShowVal] = useState<number>(0)
    const [reportContent, setReportContent] = useState<string>('');

    const resetFile = () => {
        setFileName(null)
        setFileSize(null)
        setFileType(null)
        setFilePreview(null);
    }

    const handleFormSuccess = (e:any) => {
        // Logic to execute when form is successfully submitted
        setReportContent(e.replace(/\n/g, "<br/>"));
        setShowVal(2)
        window.scrollTo({ top: 0, behavior: 'smooth' }); // You can adjust behavior as needed
    };

    const handleFileChange = (event:any) => {
        const file = event.target.files[0];
        console.log(file,"<< file")
        setSelectedFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            if (!reader.result) return 
            console.log(reader.result)
            if (
                typeof reader.result === "string" ||
                reader.result instanceof ArrayBuffer
            ) {
                const {name, size, type} = file;
                setFileName(name)
                setFileSize(size)
                setFileType(type)
                setFilePreview(reader.result as string);
            }
        };
        reader.readAsDataURL(file);
    };

    const handleFileUpload = async () => {
        if (!selectedFile) return alert("Please select a file first!");
        const formData = new FormData();
        formData.append("image", selectedFile);

        try {
            const response = await axios.post(
            // "http://localhost:3000/scanner/upload",  // upload blood report 
            "http://localhost:3000/scanner/diagnosis", //  ask AI to diagnosis the report
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            }
            );
            setUploadStatus("File uploaded successfully");
            const insertData = response.data.content.replace(/\n/g, '<br/>')
            setReturnContent(insertData)
            setShowVal(1)
        } catch (error) {
            setUploadStatus("Error uploading file");
            setReturnContent("")
            console.error("Error uploading file:", error);
        }
    }
    return (
        <>
            {/** ----- Upload Button Start ----*/}
            {showVal === 0 && 
                <div className={styles.upload}>
                    <svg className={styles.deco} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#7acded" fillOpacity="1" d="M0,64L48,85.3C96,107,192,149,288,181.3C384,213,480,235,576,229.3C672,224,768,192,864,197.3C960,203,1056,245,1152,229.3C1248,213,1344,139,1392,101.3L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
                    <h1 className={styles.title}>Upload your report.</h1>
                    <p>To provide you with the best insights, please upload your report below. Our AI system will analyze the content and deliver personalized feedback or results.</p>
                    {!filePreview && (
                        <label htmlFor='upload__input' className={styles.upload__input}>
                            <div  className={styles.upload__input__img}>
                                <img src='/images/ico/clipboard-check-fill.svg' />
                            </div>
                            <input type="file" className={styles.upload__input__input} id="upload__input" onChange={handleFileChange} />
                        </label>
                    )}
                    {filePreview && (
                        <>
                        <table className={styles.filedetails}>
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>{fileName}</td>
                                </tr>
                                <tr>
                                    <th>Size</th>
                                    <td>{fileSize}</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>{fileType}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={styles.uploadother} onClick={resetFile}>Upload another file?</div>
                        <Btn onClick={handleFileUpload}>Upload</Btn>
                        </>
                    )}
                    {uploadStatus && <h3>{uploadStatus}</h3>}
                    {returnContent && <p dangerouslySetInnerHTML={{__html:returnContent}}></p>}
                </div>
            }
            {/** ----- Upload Button End ----*/}
            {showVal === 1 && <Form onSuccess={handleFormSuccess} /> }
            {showVal === 2 && <Report content={reportContent}></Report> }
            
        </>
    );
}

export default Upload;
