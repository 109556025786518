import React from "react";
import styles from "./Footer.module.scss";
import { NavItem } from "../Header/type";
interface FooterProps {
    nav: Array<NavItem>;
    copy: string;
}
 
const Footer: React.FunctionComponent<FooterProps> = props => {
    const {copy} = props;
    return ( <footer className={styles.footer}>
        <div className="container">
            <div className={styles.top}>
                <img  src={`/images/web/logo-v2-6.svg`} alt="footer logo" className={styles.logo} />
            </div>
            <p className={styles.copy} dangerouslySetInnerHTML={{__html: copy}}></p>
        </div>
    </footer> );
}
 
export default Footer;