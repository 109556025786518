import React from "react";
import styles from './Login.module.scss';
import SocialLogin from "../../components/SocialLogin/SocialLogin";

interface Props {}
interface State {}
 
class Login extends React.Component<Props, State> {
    render() { 
        return (<div className={styles.login}>
            <div className={styles.top__wrapper}>
                <div className={styles.top}>
                    <img src='/images/login/output.png' className={styles.top__img} />
                </div>
            </div>
            <div className={styles.btm}>
                <h1 className={styles.btm__title}>Welcome !</h1>
                <h2 className={styles.btm__subtitle}>Log into your account.</h2>
                <SocialLogin href="/app/InfoInput" src="/images/ico/facebook.svg">Login with Facebook</SocialLogin>
                <SocialLogin href="/app/InfoInput" src="/images/ico/apple.svg">Login with Apple</SocialLogin>
                <SocialLogin href="/app/InfoInput" src="/images/ico/google.svg">Login with Google</SocialLogin>
            </div>
        </div> );
    }
}
 
export default Login;
