import React from "react";
import Btn from "../Btn/Btn";

import styles from "./KnowMore.module.scss";

interface KnowMoreProps {
    content: KnowMoreContentType;
}

interface KnowMoreContentType {
    title: string;
    txt: string;
    btn: {
        txt: string;
    }
}
 
const KnowMore: React.FunctionComponent<KnowMoreProps> = props => {
    const {title, txt, btn} = props.content;
    return ( <section className={styles.knowmore}>
        <div className={styles.content}>
            <h2 className={styles.title}>{title}</h2>
            <p className="txt">{txt}</p>
            {/* <Btn>{btn.txt}</Btn> */}
        </div>
        <div className={styles.img}>
            <img src="/images/dog2.png" />
        </div>
    </section> );
}
 
export default KnowMore;