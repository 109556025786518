import React from 'react';
import Routes from './Routes'
import './App.scss';

import { CheckNav } from './utils/checkNav';

function App() {
  return (
    <div className="App">
      <div className={` ${CheckNav(window.location.pathname) ? 'App__container container' : ''}`}>
        <Routes />
      </div>
    </div>
  );
}

export default App;
