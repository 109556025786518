import React from "react";
import styles from "./CommonHead.module.scss";
import Profile from "../Profile/Profile";

interface CommonHeadProps {
    noPic?: boolean;
}
interface CommonHeadState {}

class CommonHead extends React.Component<CommonHeadProps, CommonHeadState> {
    render() { 
        const {noPic=false} = this.props;
        return ( <div className={styles.commonHead__top}>
              <Profile hidePic={noPic} img="/images/profile/coco.png" name="Chan Tai Man" lv={3}/>
          </div> );
    }
}
 
export default CommonHead;