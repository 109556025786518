import React from "react";
import styles from "./DailyTask.module.scss";

interface TaskProps {
    isActive?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
    ico?: string;
}

const Task: React.FC<TaskProps> = ({ isActive, onClick, children, ico }) => {
    return (
        <div
            className={`${styles.task__btm__task} ${isActive ? styles.active : ""}`}
            onClick={onClick}
        >
            <div className={styles.task__btm__task__ico}>
                <img src={ico} />
            </div>
            <div className={styles.task__btm__task__txt}>
                {children}
                {/* <div className={styles.pt}>+100 pt</div> */}
            </div>
        </div>
    );
};

export default Task;
