const points = {
    title: "How AI help your pet?",
    points: [
        {
            title:"Smart Health Check and Symptom Tracker",
            txt:"Analyze and track pet symptoms for early detection of potential health issues, ensuring timely care and prevention.",
        },
        {
            title:"Virtual Vet Consultation and Community Support",
            txt:"Consult with virtual vets for professional advice and get insights from a supportive community of fellow pet owners.",
        },
        {
            title:"Intelligent Emergency Resources and Clinic Locator",
            txt:"Access intelligent emergency guides and quickly locate nearby veterinary clinics, ensuring immediate care during urgent situations.",
        },
    ],
    btn: {
        txt: "Contact us",
    }
}

export default points;
