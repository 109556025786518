import React, { useState } from "react";
import styles from './Accordion.module.scss';

interface AccordionProps {
    title: string;
    children: React.ReactNode;
}
 
const Accordion: React.FC<AccordionProps> = (props) => {
    const [showDetails, setShowDetails] = useState<boolean>(false)
    const {title, children} = props
    return ( <div className={`${styles.accordion} ${showDetails && styles.active}`}>
        <h3 className={styles.title} onClick={() => {setShowDetails(!showDetails)}}>{title}</h3>
        <div className={`${styles.accordion__content}`}>
            {children}
        </div>
    </div> );
}
 
export default Accordion;
