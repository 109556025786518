import React, {useState} from "react";
import styles from "./Record.module.scss";
import CommonHead from "../../components/CommonHead/CommonHead";
import Report from "../Report/Report";

interface RecordProps {}
 
const Record: React.FunctionComponent<RecordProps> = () => {
    const [activeClass, setActiveClass] = useState<number>(0);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const data = [
        "Paper", "Dr. P", "Photo"
    ]
    const demoData = [
        {date: "16/5/2024",remark: "IDEXX",},
        {date: "16/4/2024",remark: "IDEXX",},
        {date: "01/4/2024",remark: "IDEXX",},
        {date: "16/3/2024",remark: "IDEXX",},
        {date: "16/2/2024",remark: "IDEXX",},
        {date: "20/1/2024",remark: "IDEXX",},
        {date: "16/1/2024",remark: "IDEXX",},
    ]
    const ChangeTab = (i:number) => setActiveClass(i)
    const ToggleDetail = () => setShowDetail(!showDetail)
    return ( <div className={styles.record}>
        <CommonHead />
        <div className={styles.tabs}>
            {data && data.map((item, i) => {
                return (<button key={`tab-${i}`} className={`${styles.tabs__tab} ${(activeClass === i) ? styles.active : ''}`} onClick={() => ChangeTab(i)}>{item}</button>);
            })}
        </div>
        <div className={styles.contents}>
            {activeClass === 0 && <div className={styles.contents__content}>
                {!showDetail && 
                <div className={styles.contents__content__lists}>
                    {demoData && demoData.map((item, i)=> {
                        const {date, remark} = item
                        return (
                            <div className={styles.contents__content__lists__list} key={`data-paper-${i}`}>
                                <div className={styles.contents__content__lists__list__txt}>
                                    Date: {date} <br/>
                                    <small>Remark: {remark}</small>
                                    <button onClick={ToggleDetail} className={styles.contents__content__lists__list__txt__arrow}><img src="/images/ico/arrow-right-short.svg" alt="arrow-right-short" /></button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                }
                {showDetail && <div className={styles.details}>
                    <div className={styles.details__head}>
                        <button onClick={ToggleDetail} className={`${styles.contents__content__lists__list__txt__arrow} ${styles.back}`}><img src="/images/ico/arrow-right-short.svg" alt="arrow-right-short" /></button>
                        16/6/2024
                    </div>
                    <div >
                        <Report withoutHead={true} />
                    </div>
                </div>}
            </div>}
            {activeClass === 1 && <div className={styles.contents__content}>
                {!showDetail && 
                <div className={styles.contents__content__lists}>
                    {demoData && demoData.map((item, i)=> {
                        const {date, remark} = item
                        return (
                            <div className={styles.contents__content__lists__list} key={`data-paper-${i}`}>
                                <div className={styles.contents__content__lists__list__txt}>
                                    Date: {date} <br/>
                                    <small>Remark: {remark}</small>
                                    <button onClick={ToggleDetail} className={styles.contents__content__lists__list__txt__arrow}><img src="/images/ico/arrow-right-short.svg" alt="arrow-right-short" /></button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                }
                {showDetail && <div className={styles.details}>
                    <div className={styles.details__head}>
                        <button onClick={ToggleDetail} className={`${styles.contents__content__lists__list__txt__arrow} ${styles.back}`}><img src="/images/ico/arrow-right-short.svg" alt="arrow-right-short" /></button>
                        16/6/2024
                    </div>
                    <div >
                        <Report withoutHead={true} />
                    </div>
                </div>}
            </div>}{activeClass === 2 && <div className={styles.contents__content}>
                {!showDetail && 
                <div className={styles.contents__content__lists}>
                    {demoData && demoData.map((item, i)=> {
                        const {date, remark} = item
                        return (
                            <div className={styles.contents__content__lists__list} key={`data-paper-${i}`}>
                                <div className={styles.contents__content__lists__list__txt}>
                                    Date: {date} <br/>
                                    <small>Remark: {remark}</small>
                                    <button onClick={ToggleDetail} className={styles.contents__content__lists__list__txt__arrow}><img src="/images/ico/arrow-right-short.svg" alt="arrow-right-short" /></button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                }
                {showDetail && <div className={styles.details}>
                    <div className={styles.details__head}>
                        <button onClick={ToggleDetail} className={`${styles.contents__content__lists__list__txt__arrow} ${styles.back}`}><img src="/images/ico/arrow-right-short.svg" alt="arrow-right-short" /></button>
                        16/6/2024
                    </div>
                    <div >
                        <Report withoutHead={true} />
                    </div>
                </div>}
            </div>}
        </div>
    </div> );
}
 
export default Record;