import React, { useState } from 'react';
import styles from './PetHealthChecklist.module.scss';
import Task from './Task';

interface ChecklistItem {
  id: number;
  text: string;
  value: string;
  isActive: boolean;
  ico: string;
}

const initialItems: ChecklistItem[] = [
    { id: 1, text: 'Walk Distance', value: '2 km', isActive: false, ico: '/images/ico/person-walking.svg' },
    { id: 2, text: 'Playtime Duration', value: '30 minutes', isActive: false, ico: '/images/ico/play.svg' },
    { id: 3, text: 'Food Portion', value: '100 grams', isActive: false, ico: '/images/ico/food.svg' },
    { id: 4, text: 'Fresh Water Check', value: '', isActive: false, ico: '/images/ico/droplet.svg' },
    { id: 5, text: 'Grooming', value: '', isActive: false, ico: '/images/ico/balloon-heart-fill.svg' },
    { id: 6, text: 'Ear Cleaning', value: '', isActive: false, ico: '/images/ico/ear.svg' },
    { id: 7, text: 'Dental Care', value: '', isActive: false, ico: '/images/ico/plus-lg.svg' },
    { id: 8, text: 'Nail Trimming', value: '', isActive: false, ico: '/images/ico/hand-index-fill.svg' },
    { id: 9, text: 'Weight Monitoring', value: '', isActive: false, ico: '/images/ico/usb-micro.svg' },
    { id: 10, text: 'Parasite Check', value: '', isActive: false, ico: '/images/ico/postcard.svg' },
];

const PetHealthChecklist: React.FC = () => {
  const [items, setItems] = useState<ChecklistItem[]>(initialItems);

  const handleTaskClick = (id: number) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === id ? { ...item, isActive: !item.isActive } : item
      )
    );
  };

  const handleValueChange = (id: number, newValue: string) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === id ? { ...item, value: newValue } : item
      )
    );
  };

  return (
    <div className={styles.petHealthChecklist}>
      <h2>Pet Health Checklist</h2>
      <ul>
        {items.map(item => (
          <li key={item.id}>
            <Task
              ico={item.ico}
              isActive={item.isActive}
              onClick={() => handleTaskClick(item.id)}
            >
              {item.text}
              {item.value ? (
                <>
                  <input
                    type="text"
                    value={item.value}
                    onChange={e => handleValueChange(item.id, e.target.value)}
                    className={styles.valueInput}
                  />
                  <button className={styles.add}><img src="/images/ico/plus-lg.svg" alt="add"/></button>
                </>
              ) : (
                <input
                  type="checkbox"
                  checked={item.isActive}
                  onChange={() => handleTaskClick(item.id)}
                />
              )}
            </Task>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PetHealthChecklist;
