import React from "react";
import styles from "./Pets.module.scss";

interface PetsProps {}
 
const Pets: React.FunctionComponent<PetsProps> = () => {
    return ( <div className={styles.pets}>
        <div className={styles.head}>
            <h1 className={styles.title}>Pets</h1>
            <button type="button" className={styles.add}>
                <img src="/images/ico/plus-lg.svg" />
            </button>
        </div>
        <div className={styles.body}>
            <div className={styles.lists}>
                <a href="#" className={styles.item}>
                    <span className={styles.item__img}>
                        <span className={styles.item__img__wrapper}>
                            <img src="/images/profile/coco.png" alt="" />
                        </span>
                    </span>
                    <span className={styles.item__txt}>
                        <span className={styles.item__txt__title}>Beyonce</span>
                        <span className={styles.item__txt__txt}>Miniature Schnauzer</span>
                    </span>
                    <span className={styles.item__ico}>
                        <img src="/images/ico/three-dots.svg" />
                    </span>
                </a>
            </div>

            <button className={styles.add_bottom_btn}>Add another pet</button>
        </div>

    </div> );
}
 
export default Pets;