import React, { useRef, useState } from 'react';
import { gsap } from 'gsap';
import Btn from "../Btn/Btn";
import { Waypoint } from 'react-waypoint';

import styles from "./Social.module.scss";

interface SocialProps {
    bgColor?: string;
    paddingTop?: string;
    content: SocialContentType;
}
interface SocialContentType {
    title: string;
    txt: string;
    btn: {
        txt: string;
    };
}
 
const Social: React.FunctionComponent<SocialProps> = props=> {
    const { bgColor, paddingTop } = props
    const {title,txt,btn} = props.content
    const [playing, setPlaying] = useState<boolean>(false);
    const _duration = .8;
    const element001Ref = useRef<HTMLDivElement>(null);
    const element002Ref = useRef<HTMLDivElement>(null);
    const element003Ref = useRef<HTMLDivElement>(null);
    const element004Ref = useRef<HTMLDivElement>(null);
    const _handleWaypointEnter = ():void => {
        const tl = gsap.timeline({
            onComplete: () => setPlaying(false)
        });
        if(playing) return;
        setPlaying(true)
        if (element003Ref.current) {
          tl
            .fromTo(element001Ref.current, { x: "-50%", y: "-50%", opacity: 0 }, { duration: _duration, y: "30%", x: "-200%", opacity: 1 })
            .fromTo(element002Ref.current, { x: "-50%", y: "-50%", opacity: 0 }, { duration: _duration, y: "-50%", x: "200%", opacity: 1 })
            .fromTo(element003Ref.current, { x: "-50%", y: "-50%", opacity: 0 }, { duration: _duration, y: "-90%", x: "-100%", opacity: 1 })
            .fromTo(element004Ref.current, { x: "-50%", y: "-50%", opacity: 0 }, { duration: _duration, y: "90%", x: "100%", opacity: 1 })
        }
    }

    return ( 
        <section className={styles.social} id="social" style={{backgroundColor: bgColor, paddingTop: paddingTop}}>
                <div className={styles.content}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.txt}>{txt}</p>
                </div>
                <Waypoint
                    onEnter={_handleWaypointEnter}
                    topOffset="50%"
                >
                    <div className={styles.img}>
                        <div className={styles.innerwrapper}>
                            <img src="/images/video/016.png" className={styles.bg} />
                            <div className={`${styles.img2} ${styles.imgdetail}`} ref={element001Ref}><img src="/images/video/018.png" alt="" /></div>
                            <div className={`${styles.img3} ${styles.imgdetail}`} ref={element002Ref}><img src="/images/video/019.png" alt="" /></div>
                            <div className={`${styles.img4} ${styles.imgdetail}`} ref={element003Ref}><img src="/images/video/020.png" alt="" /></div>
                            <div className={`${styles.img5} ${styles.imgdetail}`} ref={element004Ref}><img src="/images/video/021.png" alt="" /></div>
                        </div>
                    </div>
                </Waypoint>
                {/* <div className={styles.btnwrapper}>
                    <Btn>{btn.txt}</Btn>
                </div> */}
            </section> 
    );
}
 
export default Social;