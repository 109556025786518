import React from "react";
import styles from "./Nav.module.scss";
import { Link } from "react-router-dom";

interface NavProps {}
interface NavState {}

class Nav extends React.Component<NavProps, NavState> {
    render() { 
        return (
            <div className={styles.nav}>
                <Link to="/app/social"><img src="/images/ico/soical.png" alt="This is home page." className={styles.ico} /><span className={styles.nav__txt}>soical</span></Link>
                <Link to="/app/"><img src="/images/ico/home.svg" alt="This is home page." className={styles.ico} /><span className={styles.nav__txt}>HOME</span></Link>                
                <Link to="/app/upload"><img src="/images/ico/clipboard-check-fill.svg" alt="This is home page." className={styles.ico} /><span className={styles.nav__txt}>ANALYSIS</span></Link>
                <Link to="/app/pets"><img src="/images/ico/pattern-3.svg" alt="This is home page." className={styles.ico} /><span className={styles.nav__txt}>Pets</span></Link>
                <Link to="/app/Login"><img src="/images/setting-white.svg" alt="This is home page." className={styles.ico} /><span className={styles.nav__txt}>SETTINGS</span></Link>
            </div>
        );
    }
}

export default Nav;
