import React from "react";
import styles from "./Premium.module.scss";

interface PremiumProps {
    content: PremiumContentProps;
}

interface PremiumContentProps {
    title: string;
    txt: string;
    img1: string;
    img2: string;
}
 
const Premium: React.FunctionComponent<PremiumProps> = props => {
    const { content } = props
    return ( <div className={styles.premium}>
        <div className={`${styles.container} container`}>
            {/* this is Premium. */}
            <div className={styles.txt}>
                <h2 className={styles.title}>{content.title}</h2>
                <p className={styles.text}>{content.txt}</p>
            </div>
            <div className={styles.images}>
                <img src={content.img1} className={styles.image1} />
                <img src={content.img2} className={styles.image2} />
            </div>
        </div>
    </div> );
}
 
export default Premium;