import React from "react";
import styles from "./PetCard.module.scss";
import { Link } from "react-router-dom";

interface PetCardProps {
    active?: boolean;
    src?: string;
    name?: string;
    type?: string;
    bgColor? : string;
}
interface PetCardState {}
 
class PetCard extends React.Component<PetCardProps, PetCardState> {
    render() { 
        const { active, src, name, type, bgColor } = this.props;
        return ( <Link style={{ background: bgColor }} to={`/app/petDetails#id=${name}`} className={`${styles.petCard} ${active ? styles.active : null}`}>
            <span className={styles.petCard__imgwrapper}>
                <img src={src} className={styles.petCard__img__img} />
            </span>
            <span className={styles.petCard__name}>
                {name}
            </span>
            <span className={styles.petCard__type}>
                {type}
            </span>
        </Link> );
    }
}
 
export default PetCard;