import React from 'react';
import Header from '../../../components/Web/Header/Header';

import { Helmet } from 'react-helmet';

import styles from "./Index.module.scss";
import Hero from '../../../components/Web/Hero/Hero';
import Details from '../../../components/Web/Details/Details';
import KnowMore from '../../../components/Web/KnowMore/KnowMore';
import Points from '../../../components/Web/Points/Points';
import Social from '../../../components/Web/Social/Social';
import Footer from '../../../components/Web/Footer/Footer';
import Premium from '../../../components/Web/Premium/Premium';
import WorkingWith from '../../../components/Web/WorkingWith/WorkingWith';

// content
import seo from '../../../content/web/seo';
import header from '../../../content/web/header';
import hero from '../../../content/web/hero';
import details from '../../../content/web/details';
import knowmore from '../../../content/web/knowmore';
import points from '../../../content/web/points';
import social from '../../../content/web/social';
import footer from '../../../content/web/footer';
import premium from '../../../content/web/home/premium';

interface IndexProps {}
 
const Index: React.FC<IndexProps> = () => {
    const {title,description,keywords,url,type} = seo;
    return (
      <div className={styles.index}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content={type} />
        </Helmet>
        <Header nav={header.nav} contact={header.contact} />
        <Hero content={hero}/>
        <Details content={details} />
        <KnowMore content={knowmore} />
        <Points content={points} />
        <Social content={social} />
        <Premium content={premium} />
        <WorkingWith />
        {/* <Review content={review} /> */}
        {/* <Promos content={promos} /> */}
        <Footer nav={header.nav} copy={footer.copy} />
      </div>
    );
}
 
export default Index;