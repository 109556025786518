import React from "react";
import styles from './Btn.module.scss';
import { Link } from "react-router-dom";

interface BtnProps {
    children: React.ReactNode;
    type?: "button" | "submit" | "reset";
    href?: string;
    onClick?: () => void;
}

interface BtnState {}

class Btn extends React.Component<BtnProps, BtnState> {
    static defaultProps = {
        type: "button",
    };

    render() {
        const { children, type, onClick, href } = this.props;
        if(href) return <Link className={styles.btn} to={href}>{children}</Link>
        return <button type={type} className={styles.btn} onClick={onClick}>{children}</button>;
    }
}

export default Btn;
