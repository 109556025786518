import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Chat from './pages/Chat/Chat';
import Report from './pages/Report/Report';
import Social from './pages/Social/Social';
import ReportForm from './pages/ReportForm/ReportForm';
import Nav from './components/Nav/Nav';
import Upload from './pages/Upload/Upload';
import PetDetails from './pages/PetDetails/PetDetails';
import Index from './pages/web/Index/Index';
import InfoInput from './pages/InfoInput/InfoInput';
import Routine from './pages/Routine/Routine';
import Spending from './pages/Spending/Spending';
import Notice from './pages/Notice/Notice';
import Record from './pages/Record/Record';
import DigitalPetHealthRecords from './pages/web/features/records/digital-pet-health-records';
import SocialPage from './pages/web/features/social/social';
import Post from './pages/Post/Post';
import Question from './pages/Question/Question';
import Pets from './pages/Pets/Pets';

import { CheckNav } from './utils/checkNav';
import { IsApp } from './utils/isApp';
import PremiumPage from './pages/web/features/premium/premium';

const AppRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <div className={`wrapper ${IsApp(window.location.pathname) ? 'wrapper--app' : ''}`}>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/features/records" element={<DigitalPetHealthRecords />} />
        <Route path="/features/social" element={<SocialPage />} />
        <Route path="/features/premium" element={<PremiumPage />} />
        <Route path="/app/" element={<Home />} />
        <Route path="/app/login" element={<Login />} />
        <Route path="/app/InfoInput" element={<InfoInput />} />
        <Route path="/app/Routine" element={<Routine />} />
        <Route path="/app/Spending" element={<Spending />} />
        <Route path="/app/Recurring" element={<Notice />} />
        <Route path="/app/Record" element={<Record />} />
        <Route path="/app/chat" element={<Chat />} />
        <Route path="/app/report" element={<Report />} />
        <Route path="/app/reportForm" element={<ReportForm />} />
        <Route path="/app/social" element={<Social />} />
        <Route path="/app/upload" element={<Upload />} />
        <Route path="/app/petDetails" element={<PetDetails />} />
        <Route path="/app/Post" element={<Post />}></Route>
        <Route path="/app/Question" element={<Question />}></Route>
        <Route path="/app/Pets" element={<Pets />}></Route>
      </Routes>
      {CheckNav(location.pathname) && <Nav />}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;
