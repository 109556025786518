import React from "react";
import styles from "./TxtImg.module.scss";

interface TxtImgProps {
    content : TxtImgContentProps;
}

interface TxtImgContentProps {
    title: string;
    txt: string;
    img: string;
}
 
const TxtImg: React.FunctionComponent<TxtImgProps> = props => {
    const { content } = props
    return ( <div className={styles.txtImg}>
        <div className={`${styles.container} container`}>
            <div className={styles.txt}>
                <h2 className={styles.title}>{content.title}</h2>
                <p className={styles.text} dangerouslySetInnerHTML={{__html: content.txt}}></p>
            </div>
            <div className={styles.img}>
                <img src={content.img} alt={content.title} />
            </div>
        </div>
    </div> );
}
 
export default TxtImg;