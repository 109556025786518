import React from "react";
import styles from "./Details.module.scss";

interface DetailsProps {
    col?:number | 3;
    content: DetailsContentType;
}

interface DetailsContentType {
    title: string;
    txt: string;
    nav: Array<DataType>;
}

interface DataType {
    src: string;
    title: string;
    txt: string;
}
 
const Details: React.FunctionComponent<DetailsProps> = props => {
    const {col = 3} = props
    const {title, txt, nav} = props.content;
    return ( <section className={styles.details} id="details">
        <div className="container">
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.txt}>{txt}</p>
            <div className={`${styles.points}`}>
                {nav && nav.map((item, i) => {
                    const {src, title, txt} = item
                    return (
                        <div className={`${styles.point} ${styles['col'+col]}`} key={`pointkey${i}`}>
                            <div className={styles.ico}>
                                <img src={src} alt="Details img" />
                            </div>
                            <h3 className={styles.point__title}>{title}</h3>
                            <p className={styles.point__txt}>{txt}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    </section> );
}
 
export default Details;