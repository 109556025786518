import React from "react";
import styles from "./Conversation.module.scss";

interface ConversationState {}
interface ConversationProps {
    left?: boolean;
    src: string;
    name: string;
    children: React.ReactNode;
}

class Conversation extends React.Component<ConversationProps, ConversationState> {
    render() { 
        const { left=true, src, name, children } = this.props;
        return ( <div className={`${styles.conversation} ${left?'':styles.right}`}>
            <div className={styles.conversation__head}>
                <div className={styles.conversation__head__img}>
                    <img src={src} />
                </div>
                <div className={styles.conversation__head__txt}>{name}</div>
            </div>
            <div className={styles.conversation__body}>{children}</div>
        </div> );
    }
}
 
export default Conversation;