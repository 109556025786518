import React from "react";
import styles from "./SuperHr.module.scss";

interface SuperHrProps {}
interface SuperHrState {}
 
class SuperHr extends React.Component<SuperHrProps, SuperHrState> {
    render() { 
        return ( <img className={styles.superhr} src="/images/super-hr.svg" /> );
    }
}
 
export default SuperHr;