import React, { useRef, useState } from 'react';
import { gsap } from 'gsap';
import styles from "./Points.module.scss";
import Btn from "../Btn/Btn";
import { Waypoint } from 'react-waypoint';

interface PointsProps {
    content: PointsContentType;
}
interface PointsContentType {
    title: string;
    points: Array<PointsItem>;
    btn: {
        txt: string;
    }
}
interface PointsItem {
    title: string;
    txt: string;
}
 
const Points: React.FunctionComponent<PointsProps> = props => {
    const {title, btn, points} = props.content;
    const [playing, setPlaying] = useState<boolean>(false);
    const element001Ref = useRef<HTMLDivElement>(null);
    const element002Ref = useRef<HTMLDivElement>(null);
    const element003Ref = useRef<HTMLDivElement>(null);
    const _handleWaypointEnter = ():void => {
        const tl = gsap.timeline({
            onComplete: () => setPlaying(false)
        });
        if(playing) return;
        setPlaying(true)
        if (element003Ref.current) {
          tl
            .fromTo(element001Ref.current, { opacity: 0 }, { duration: 1, x: 0, opacity: 1 })
            .fromTo(element002Ref.current, { x: 0, y:0, opacity: 0 }, { duration: 1, y:0, x:'-20%', opacity: 1 })
            .fromTo(element003Ref.current, { x: '-20%', y:0, opacity: 0 }, { duration: 1, y:0, x:0, opacity: 1 })
        }
    }

    return ( 
        <Waypoint
            onEnter={_handleWaypointEnter}
        >
            <section className="container" id="ai">
                <div className={`${styles.points}`}>
                    <div className={styles.imgwrapper}>
                        <div className={styles.inner}>
                            <div ref={element001Ref} className={styles.img1}><img src="/images/video/007.png" /></div>
                            <div ref={element002Ref} className={styles.img2}><img src="/images/video/008.png" /></div>
                            <div ref={element003Ref} className={styles.img3}><img src="/images/video/012.png" /></div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <h2 className={styles.title}>{title}</h2>
                        {points && points.map((item, i) => {
                            const {title, txt} = item
                            return (
                                <div className={styles.points__point} key={`points-pt-${i}`}>
                                    <h3 className={styles.points__point__titles}>{title}</h3>
                                    <p className={styles.points__point__txt}>{txt}</p>
                                </div>
                            )
                        })}
                        <div className={styles.btn}>
                            <Btn href='mailto:marketing@vowix.co'>{btn.txt}</Btn>
                        </div>
                    </div>        
                </div>
            </section>
        </Waypoint>
     );
}
 
export default Points;