const HomeData = {
    shortcuts: [
      {
        ico: "/images/ico/record-btn-fill.svg",
        txt: "Record",
        link: "Record",
      },
        {
          ico: "/images/ico/clipboard-check-fill-blue.svg",
          txt: "Analysis",
          link: "upload",
        },
        {
          ico: "/images/ico/calendar-event-fill-blue.svg",
          txt: "Routine",
          link: "Routine",
        },
        {
          ico: "/images/ico/doctor.svg",
          txt: "Doctor P",
          link: "chat",
        },
        {
          ico: "/images/ico/cash-coin.svg",
          txt: "Spending",
          link: "Spending",
        },
        {
          ico: "/images/notice.svg",
          txt: "Recurring",
          link: "Recurring",
        },
        {
          ico: "/images/ico/file-earmark-arrow-down-fill.svg",
          txt: "Files",
          link: "Files",
        }
      ]
};

export default HomeData;
