const details = {
    title: "A super app for your pet",
    txt: "Awesome features to help your pet throughout its life!",
    nav: [
        {
            src: "https://flockr.social/icons/health-records.svg",
            title: "Medical records",
            txt: "Complete summary of your pet's life in one place and ready to share with your veterinarian."
        },
        {
            src: "https://flockr.social/icons/calendar.svg",
            title: "Calendar",
            txt: "Organize your best friend's entire agenda and intelligently monitor the dates of the next vaccines, deworming, anti-flea and medication."
        },
        {
            src: "https://flockr.social/icons/profile.svg",
            title: "Diseases and Allergies",
            txt: "Have your pet's illnesses recorded, fill in the symptoms and treatments."
        },
        {
            src: "https://flockr.social/icons/weight.svg",
            title: "Weight control",
            txt: "History of your pet's weight, with a graph showing the evolution over time."
        },
        {
            src: "https://flockr.social/icons/appointments.svg",
            title: "Appointments",
            txt: "Register all visits to the vet, such as exams, surgeries and routine appointments."
        },
        {
            src: "https://flockr.social/icons/foam.svg",
            title: "Bath and grooming",
            txt: "Register all visits to the Pet Shop and take control of your pet's hygiene."
        }
    ]
}

export default details;
