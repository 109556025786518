import React from "react";
import styles from "./Head.module.scss";

interface HeadProps {
    title: string;
}

const Head: React.FunctionComponent<HeadProps> = props => {
    const { title } = props;
    return (
        <div className={styles.head}>
            <button className={styles.back}>
                <img src="/images/ico/chevron-left.svg" alt="Back" />
            </button>
            <h1 className={styles.title}>{title}</h1>
        </div>
    );
};

export default Head;
